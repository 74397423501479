@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --themeColor: #3a9545;
  --textColor: #fafafa;
}
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.card-border {
  border: 1px solid rgba(229, 231, 235, 1);
  border-radius: 0.5rem;
  background: #fff;
}

.card-body {
  padding: 1.25rem;
}

.Success-bg {
  width: 95px;
  align-items: center;
  border-radius: 21px;
  display: flex;
  justify-content: center;
  padding: 7px 0;
  color: #fff !important;
  border-radius: 21px;
}

.Failed-bg {
  background-color: #f87171;
}

.ant-switch-checked {
  background-color: #007b7d !important;
}

:where(.css-dev-only-do-not-override-d2lrxs).ant-modal .ant-modal-content {
  background: none !important;
  box-shadow: none !important;
}

.ant-modal .ant-modal-content {
  background: none !important;
  box-shadow: none !important;
}

.ant-modal-content {
  background: none !important;
  box-shadow: none !important;
}

.ant-modal-close {
  display: none !important;
}

:where(.css-dev-only-do-not-override-d2lrxs).ant-modal
  .ant-modal-content
  .ant-modal-close {
  display: none;
}

:where(.css-dev-only-do-not-override-1uq9j6g).ant-picker
  .ant-picker-input
  > input[disabled] {
  color: var(--themeColor) !important;
  color: var(--textColor) !important;
}

.logo-text {
  font-weight: 700;
  color: var(--themeColor);
  font-size: 20px;
}
