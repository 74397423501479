.header-brand-privacy {

    h1 {
        color: #057d1c !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.policy-container {
    // width: 90%;
    // max-width: 900px;
    // margin: 200px auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: #000;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;

    &:hover {
        box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
    }

    .margin-bottom {
        margin-bottom: 20px;
    }

    .bold-number {
        font-weight: bold;
        color: #057d1c;
        font-size: 1.1em;
    }

    h1,
    h2,
    h3 {
        color: #000000;
        font-weight: bold;
        margin-bottom: 15px;
        line-height: 1.2;
    }

    h1 {
        font-size: 2em;
        margin-top: 0;
    }

    h2 {
        font-size: 1.75em;
    }

    h3 {
        font-size: 1.5em;
    }

    ul {
        list-style-type: disc;
        padding-left: 20px;
        margin-bottom: 15px;

        li {
            margin-bottom: 10px;
            padding-left: 5px;
            position: relative;
        }
    }

    @media (max-width: 600px) {
        padding: 15px;
        font-size: 16px;
        margin: 120px auto;

        h1 {
            font-size: 1.5em;
        }

        h2 {
            font-size: 1.25em;
        }

        h3 {
            font-size: 1.1em;
        }
    }
}