.AboutUsLandingPage_parent {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .backgroundImage {
    position: absolute;
    width: 987px;
    height: 1039px;
    z-index: -1;
  }

  .AboutUsLandingPage_main {
    position: relative;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;

    .AboutUsLandingPage_container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-family: "Roboto", sans-serif;
      margin-bottom: 100px;
      gap: 10px;
      .aboutus_heading {
        color: #313131;
        font-size: 85px;
        font-weight: 700;
      }
      .aboutus_description {
        text-align: center;
        line-height: 1.9;
      }

      .lineAnimation {
        position: absolute;
        z-index: -1;
        // background-color: red;
        right: -40px;
        // bottom: -100;
        img {
          height: 1030px;
          width: 700px;
        }
      }
    }
    .AboutUsLandingPage_container_1 {
      display: flex;
      // justify-content: space-around;
      align-items: center;
      // width: 100%;
      margin-top: 150px;
      // background-color: red;

      .aboutUsLeft {
        width: 45%;
        display: flex;
        //   justify-content: center;
        position: relative;
        .aboutUsLeft_Box {
          background-image: linear-gradient(#0faa2b, #057d1c);
          height: 352px;
          width: 546px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;

          .aboutUsLeft_Phone {
            position: absolute;
            img {
              height: 500px;
              width: 240px;
              z-index: 1;
              margin-bottom: 250px;
            }
          }
        }
      }

      .aboutUsRight {
        width: 45%;
        color: #2c3e50;
        margin-bottom: 200px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        h1 {
          font-size: 39px;
          font-weight: bold;
        }

        p {
          font-size: 16px;
          line-height: 1.8;
          color: #555;
        }

        .learnMoreButton {
          margin-top: 20px;
          width: 185px;
          background-color: #000f37;
          align-items: center;
          color: #fff;
          padding: 10px 20px;
          border: none;
          cursor: pointer;
          transition: background 0.3s;

          &:hover {
            background-color: #2c3e50;
          }
        }
      }
    }

    .AboutUsLandingPage_container_2 {
      display: flex;
      // justify-content: space-around;
      align-items: center;
      width: 100%;
      margin-top: 200px;
      // background-color: red;

      .aboutUsLeft {
        width: 45%;
        display: flex;
        justify-content: center;
        position: relative;
        .aboutUsLeft_Box {
          background-image: linear-gradient(#0faa2b, #057d1c);
          height: 352px;
          width: 546px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;

          .aboutUsLeft_Phone {
            position: absolute;
            img {
              height: 500px;
              width: 240px;
              z-index: 1;
              margin-bottom: 250px;
            }
          }
        }
      }

      .aboutUsRight {
        width: 45%;
        color: #2c3e50;
        margin-bottom: 150px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        h1 {
          font-size: 39px;
          font-weight: bold;
        }

        p {
          font-size: 16px;
          line-height: 1.8;
          color: #555;
        }

        .learnMoreButton {
          margin-top: 20px;
          width: 185px;
          background-color: transparent;
          align-items: center;
          border: 2px solid #313131;
          color: #313131;
          padding: 10px 20px;
          // border: none;
          cursor: pointer;
          transition: background 0.3s;

          &:hover {
            background-color: #000f37;
            color: var(--textColor);
          }
        }
      }
    }

    .AboutUsLandingPage_container_3 {
      display: flex;
      // justify-content: space-around;
      align-items: center;
      width: 100%;
      margin-top: 150px;
      // background-color: red;

      .aboutUsLeft {
        width: 45%;
        display: flex;
        //   justify-content: center;
        position: relative;
        .aboutUsLeft_Box {
          background-image: linear-gradient(#0faa2b, #057d1c);
          height: 352px;
          width: 546px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;

          .aboutUsLeft_Phone {
            position: absolute;
            img {
              height: 500px;
              width: 240px;
              z-index: 1;
              margin-bottom: 250px;
            }
          }
        }
      }

      .aboutUsRight {
        width: 45%;
        color: #2c3e50;
        margin-bottom: 200px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        h1 {
          font-size: 39px;
          font-weight: bold;
        }

        p {
          font-size: 16px;
          line-height: 1.8;
          color: #555;
        }

        .learnMoreButton {
          margin-top: 20px;
          width: 185px;
          background-color: #000f37;
          align-items: center;
          color: #fff;
          padding: 10px 20px;
          border: none;
          cursor: pointer;
          transition: background 0.3s;

          &:hover {
            background-color: #2c3e50;
          }
        }
      }
    }
  }
  .semiCircle {
    position: absolute;
    // background-color: red;
    height: 358px;
    width: 184px;
    top: -20vh;
    left: 0;
    img {
      height: 100%;
      width: 100%;
    }
  }
}

@media (max-width: 500px) {
  .aboutus_heading {
    font-size: 35px !important;
    // background-color: red;
  }
  .aboutus_description {
    font-size: 11px;
  }
}

@media (max-width: 1700px) {
  .AboutUsLandingPage_container_1 {
    gap: 50px;
    width: 100%;
  }

  .AboutUsLandingPage_container_2 {
    gap: 50px;
    width: 100%;
  }

  .AboutUsLandingPage_container_3 {
    gap: 50px;
    width: 100%;
  }
}

@media (max-width: 1000px) {
  .aboutUsRight {
    h1 {
      font-size: 30px !important;
    }

    p {
      font-size: 12px !important;
      line-height: 1.8;
      color: #555;
    }
  }
}

@media (max-width: 800px) {
  .aboutUsLeft {
    img {
      height: 400px !important;
      width: 200px !important;
      z-index: 1;
      margin-bottom: 250px;
    }
  }
}

@media (max-width: 650px) {
  .AboutUsLandingPage_main {
    width: 95% !important;

    .AboutUsLandingPage_container_1 {
      flex-direction: column;
      width: 95% !important;

      .aboutUsLeft {
        width: 95% !important;

        .aboutUsLeft_Box {
          height: 302px !important;
          width: 95% !important;

          .aboutUsLeft_Phone {
            img {
              height: 300px !important;
              width: 150px !important;
            }
          }
        }
      }

      .aboutUsRight {
        width: 99% !important;

        h1 {
          font-size: 30px !important;
          // max-width: 100%;
        }

        p {
          font-size: 12px;
          line-height: 1.8;
          color: #555;
        }
      }
    }

    .AboutUsLandingPage_container_2 {
      width: 95% !important;
      flex-direction: column-reverse;

      .aboutUsLeft {
        width: 95% !important;

        .aboutUsLeft_Box {
          height: 302px !important;
          width: 95% !important;

          .aboutUsLeft_Phone {
            img {
              height: 300px !important;
              width: 150px !important;
            }
          }
        }
      }

      .aboutUsRight {
        width: 99% !important;

        h1 {
          font-size: 30px !important;
          // max-width: 100%;
        }

        p {
          font-size: 12px;
          line-height: 1.8;
          color: #555;
        }
      }
    }

    .AboutUsLandingPage_container_3 {
      flex-direction: column;
      width: 95% !important;

      .aboutUsLeft {
        width: 95% !important;

        .aboutUsLeft_Box {
          height: 302px !important;
          width: 95% !important;

          .aboutUsLeft_Phone {
            img {
              height: 300px !important;
              width: 150px !important;
            }
          }
        }
      }

      .aboutUsRight {
        width: 99% !important;

        h1 {
          font-size: 30px !important;
          // max-width: 100%;
        }

        p {
          font-size: 12px;
          line-height: 1.8;
          color: #555;
        }
      }
    }
  }
}
