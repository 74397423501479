.stores {
  display: flex;
  height: 15.625vw;
  justify-content: center;
  align-items: center;
  gap: 50px;
  border-radius: 20px;

  .appStore,
  .playStore {
    display: flex;
    justify-content: center;
    align-items: center;
    // gap: 10px;
    color: var(--textColor);
    // width: 10vw;
    font-family: "Roboto", sans-serif;
    background-color: #000000;
    border-radius: 10px;
    padding: 5px 10px;
    gap: 20px;
    cursor: pointer;
    img {
      height: 40px;
    }
    p {
      font-size: 15px;
      margin: 0;
      line-height: 1.1;
    }
    span {
      font-size: 25px;
      // font-weight: 500;
    }
  }
}

@media (max-width: 1700px) {
  .stores {
    gap: 15px !important;
  }

  .stores {
    margin-top: 70px;
  }
  .appStore,
  .playStore {
    padding: 5px 10px !important;

    img {
      height: 20px !important;
    }
    p {
      font-size: 11px !important;
      margin: 0 !important;
      line-height: 1.1 !important;
    }
    span {
      font-size: 20px !important;
    }
  }
}

@media (max-width: 800px) {
  .appStore,
  .playStore {
    padding: 5px 10px !important;

    img {
      height: 20px !important;
    }
    p {
      font-size: 11px !important;
      margin: 0 !important;
      line-height: 1.1 !important;
    }
    span {
      font-size: 20px !important;
    }
  }
}

@media (max-width: 650px) {
  .stores {
    gap: 15px !important;
  }

  .appStore,
  .playStore {
    padding: 5px 10px !important;

    img {
      height: 20px !important;
    }
    p {
      font-size: 11px !important;
      margin: 0 !important;
      line-height: 1.1 !important;
    }
    span {
      font-size: 15px !important;
    }
  }
}

@media (max-width: 450px) {
  .stores {
    gap: 15px !important;
  }
  .appStore,
  .playStore {
    padding: 4px 8px !important;
    gap: 8px !important;
    img {
      height: 23px !important;
    }
    p {
      font-size: 9px !important;
      margin: 0 !important;
      line-height: 1.1 !important;
    }
    span {
      font-size: 15px !important;
    }
  }
}
