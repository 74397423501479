.homeLandingPage-Main {
  position: relative;
  width: 100%;
  // height: auto;
  display: flex;
  flex-direction: column;

  overflow-x: hidden;
  .homeLandingPage_container {
    // gap: 100px;
    .homeLandingPage_container {
      background-color: #287e1b;
    }
  }
}
