.ourClients {
  width: 100%;
  position: relative;
  margin-top: 100px;
  .ourClients_container {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    z-index: 1;
    .backgroundAnimation {
      position: absolute;
      z-index: -9999;
      img {
        height: 950px;
        margin-top: -400px;
      }
    }
    .ourClients_main {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-family: "Roboto", sans-serif;
      margin-bottom: 100px;
      gap: 10px;
      .ourClients_heading {
        color: #313131;
        font-size: 85px;
        font-weight: 700;
      }
      .ourClients_description {
        text-align: center;
        line-height: 1.9;
      }
    }
    .clientList {
      display: flex;
      justify-content: center;
      gap: 20px;
      flex-wrap: wrap;
      margin-bottom: 100px;

      .client_box {
        height: 9.625vh;
        width: 18.594vw;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
          0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffff;

        .clientImage {
          max-height: 90%;
          max-width: 70%;
        }
      }
    }

    .semiCircle2 {
      rotate: 180deg;
      position: absolute;
      height: 358px;
      width: 184px;
      top: -25vh;
      right: 0;

      img {
        display: flex;
        justify-content: flex-end;
        height: 100%;
        width: 100%;
      }
    }
  }
}

@media (max-width: 550px) {
  .ourClients {
    margin-top: 10px !important;
    .ourClients_heading {
      font-size: 45px !important;

      // background-color: red;
    }
  }
}

@media (max-width: 1070px) {
  .client_box {
    height: 75px !important;
    width: 180px !important;
  }
}

@media (max-width: 475px) {
  .clientList {
    // gap: 10px !important;
    .client_box {
      height: 115px !important;
      width: 320px !important;
      // margin-left: 30%;
      gap: 5px;
    }
  }
}
