.DownloadApp_main {
  display: flex;
  margin: 0 auto;
  gap: 60px;
  width: 80%;
  // height: auto;
  //   justify-content: center;
  //   align-items: center;
  //   background-color: red;
  margin: 100px auto;

  .DownloadApp_leftSide {
    display: flex;
    height: 0px !important;
    flex-direction: column;
    gap: 40px;
    width: 50%;
    .downloadApp_heading-container {
      .downloadApp_heading {
        color: #313131;
        font-size: 85px;
        font-weight: 700;
        line-height: 1;
      }
    }

    .downloadApp_desc-container {
      .downloadApp_desc {
        // text-align: center;
        line-height: 1.9;
        color: #575757;
      }
    }

    .downloadApp_store_container {
      display: flex !important;
      padding: 0 !important;
      height: 0 !important;
    }
    .stores {
      height: 40px !important; //to remove extra hight from this container and this .stores is used in DownloadStores.js
    }
  }

  .DownloadApp_rightSide {
    display: flex;
    gap: 50px;
    justify-content: space-around;
    width: 50%;
    position: relative;
    .image_Container1 {
      display: flex;

      .image_1 {
        height: 522px;
        width: 256px;
        z-index: 1;
        margin-top: 110px;
      }

      .image_Container2 {
        position: absolute;

        .image_2 {
          height: 629px;
          width: 309px;
          margin-left: 160px;
        }
      }
    }
  }
}

@media (max-width: 1230px) {
  .DownloadApp_rightSide {
    width: 30% !important;

    .image_1 {
      height: 500px;
      width: 200px;
      z-index: 1;
      margin-top: 110px;
    }

    .image_Container2 {
      .image_2 {
        height: 600px;
        width: 290px;
        margin-left: 160px;
      }
    }
  }
}

@media (max-width: 870px) {
  .DownloadApp_main {
    margin-bottom: 60vh;
    flex-direction: column-reverse;
    // gap: 50px;
    // height: 100%;
    .DownloadApp_rightSide {
      width: 50% !important;

      .image_1 {
        height: 400px !important;
        width: 250px !important;
        z-index: 1;
        margin-top: 110px;
      }

      .image_Container2 {
        .image_2 {
          height: 500px !important;
          // width: 390px !important;
          margin-left: 130px;
        }
      }
    }
    .DownloadApp_leftSide,
    .DownloadApp_rightSide {
      width: 100% !important;
      text-align: center;

      .downloadApp_heading {
        font-size: 60px;
      }

      .downloadApp_desc {
        font-size: 16px;
      }
    }
    .image_Container1 {
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  .downloadApp_store_container {
    justify-content: center;
    align-items: center;
    margin-bottom: 10vh !important;
  }
}

@media (max-width: 545px) {
  .DownloadApp_main {
    .DownloadApp_rightSide {
      width: 50vw !important;
    }

    .downloadApp_heading-container {
      .downloadApp_heading {
        font-size: 12.8vw !important;
      }
    }
    .downloadApp_store_container {
      margin-bottom: 10vh !important;
    }
  }
}

@media (max-width: 470px) {
  .DownloadApp_main {
    padding-bottom: 13vh;
    .DownloadApp_rightSide {
      .image_1 {
        height: 360px !important;
        width: 280px !important;
        z-index: 1;
        margin-top: 110px !important;
      }

      .image_Container2 {
        .image_2 {
          height: 470px !important;
          width: 350px !important;
          margin-left: 86px !important;
        }
      }
    }
    .downloadApp_store_container {
      // margin-bottom: 10px !important;
    }
  }
}
