.faqs {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .faqs_main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 50px;

    .ourClients_heading {
      font-size: 48px;
      font-weight: bold;
      color: #313131;
    }
  }

  .faqContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .faq {
      border: 1px solid #dcdcdc;
      border-radius: 5px;
      padding: 25px;
      background-color: #f0f7f4;

      .faq-question {
        display: flex;
        // justify-content: space-between;
        align-items: center;
        gap: 20px;
        background-color: #f0f7f4;
        width: 676px;
        // justify-content: center;
        cursor: pointer;

        h3 {
          font-size: 15px;
          color: #313131;
          //   font-weight: 600;
        }

        span {
          .cancel {
            height: 4px;
            width: 30px;
          }
        }
      }

      .faq-answer {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 700px;
        padding: 10px;
        height: 200px;
        // width: 600px;
        background-color: #ffffff;
        p {
          font-size: 16px;
          line-height: 1.6;
        }
      }
    }
  }

  .semiCircle {
    position: absolute;
    // background-color: red;
    height: 358px;
    width: 184px;
    // top: 20vh;
    left: 0;
    img {
      height: 100%;
      width: 100%;
    }
  }

  .lineAnimation {
    position: absolute;
    // background-color: red;
    right: 0;
    z-index: -1;
    img {
      height: 1030px;
      width: 700px;
    }
  }
}

@media (max-width: 760px) {
  .faq-question {
    width: 69vw !important;
    // height: 3vh !important;
    // justify-content: center;
    // z-index: 1;

    span {
      img {
        max-width: none;
      }
    }
  }
  .faq-answer {
    // padding: 100px;
  }
  .semiCircle {
    z-index: -1;
  }
}
