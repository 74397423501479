.footer {
  color: var(--textColor);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 1;

  .footer_main {
    position: relative;
    background-color: #02300a;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    padding: 50px;
    color: #fff;
    flex-wrap: wrap;
    margin-top: 100px;
    position: relative;
    z-index: 999;
    // height: 50vh;

    .AdSimpleLogo {
      position: absolute;
      height: 286px;
      width: 1029px;
      z-index: -1;
    }

    .appLogo {
      flex: 1;
      max-width: 300px;

      h1 {
        margin-bottom: 25px;
        font-size: 2vh;
        font-weight: 800;
      }

      p {
        margin-bottom: 20px;
        font-size: 15px;
      }

      .socialmediaIcon {
        display: flex;
        gap: 10px;

        .socialmediaIcon_subContainer {
          height: 30px;
          width: 30px;
          border: 1px solid var(--textColor);
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
        }

        img {
          height: 20px;
          width: 20px;
          cursor: pointer;
        }
      }
    }

    .usefulLinks,
    .ourServices,
    .contactInfo {
      flex: 1;
      max-width: 250px;

      h1 {
        margin-bottom: 25px;
        font-size: 2vh;
        font-weight: 800;
      }

      .usefulLinks_section,
      .ourServices_section,
      .contactInfo_section {
        display: flex;
        flex-direction: column;
        gap: 10px;

        a,
        p {
          font-size: 15px;
          text-decoration: none;
          color: #fff;

          &:hover {
            color: #8cffa4;
          }
        }
      }
    }

    .contactInfo_section {
      .contactInfo_subSection {
        display: flex;
        gap: 10px;
        align-items: flex-start;

        img {
          height: 20px;
          width: 20px;
        }

        p {
          margin: 0;
          font-size: 15px;
        }
      }
    }
  }

  .rocketUpIcon {
    position: absolute;
    bottom: 65px;
    right: 20px;
    width: 66px;
    height: 66px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-image: linear-gradient(#0faa2b, #057d1c);
    cursor: pointer;
    z-index: 1000;

    img {
      position: relative;
      width: 27px;
      top: 0;
      transition: top ease 0.5s;
      &:hover {
        top: -50px;
      }
    }
  }

  .footer-bottom {
    background-image: linear-gradient(#0faa2b, #057d1c);
    width: 100%;
    height: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
  }
}

/* Responsive styles */
@media (max-width: 1060px) {
  .footer {
    .footer_main {
      gap: 60px;
      // height: 40vh !important;
      // width: 100% !important;
    }
  }
}

@media (max-width: 880px) {
  .footer {
    .footer_main {
    }
    .rocketUpIcon {
      width: 60px !important;
      height: 60px !important;
      // position: absolute;
      // left: 20px;
      // bottom: 20px;
    }
  }
}

@media (max-width: 752px) {
  .footer {
    .footer_main {
      .appLogo {
        h1 {
          font-size: 1.4vh !important;
        }

        p {
          font-size: 10px !important;
        }

        .socialmediaIcon {
          .socialmediaIcon_subContainer {
            height: 20px !important;
            width: 20px !important;
          }

          img {
            height: 15px !important;
            width: 15px !important;
          }
        }
      }

      .usefulLinks,
      .ourServices,
      .contactInfo {
        h1 {
          font-size: 1.4vh;
        }

        .usefulLinks_section,
        .ourServices_section,
        .contactInfo_section {
          a,
          p {
            font-size: 10px;
          }
        }
      }
    }
  }
}

@media (max-width: 610px) {
  .footer {
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .footer_main {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;

      .appLogo {
        h1 {
          text-align: center;
          font-size: 3vh !important;
        }

        p {
          font-size: 15px !important;
          text-align: center;
        }
        .socialmediaIcon {
          display: flex;
          align-items: center;
          justify-content: center;
          .socialmediaIcon_subContainer {
            height: 40px !important;
            width: 40px !important;
          }

          img {
            height: 25px !important;
            width: 25px !important;
          }
        }
      }
      .usefulLinks,
      .ourServices,
      .contactInfo {
        h1 {
          font-size: 3vh !important;
          text-align: center;
        }

        .usefulLinks_section,
        .ourServices_section,
        .contactInfo_section {
          a,
          p {
            font-size: 15px !important;
            text-align: center;
          }
        }
        .contactInfo_section {
          .contactInfo_subSection {
            display: flex;
            gap: 10px;
            justify-content: center;
            align-items: center;
            // align-items: flex-start;

            img {
              height: 20px;
              width: 20px;
            }

            p {
              margin: 0;
              font-size: 15px !important;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
