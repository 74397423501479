.header-container {
  width: 100%;
  padding-top: 40px;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  width: 80%;
  margin: 0 auto;
}

.header-links {
  display: flex;
  gap: 30px;
  font-family: "Roboto", sans-serif;

  .hamburger_auth {
    display: none;
  }

  a {
    text-decoration: none;
    color: var(--textColor);
    font-size: 1.2vw;
    position: relative;
    transition: color 0.3s ease;

    &::after {
      content: "";
      position: absolute;
      bottom: -2px;
      left: 0;
      height: 2px;
      background-color: var(--textColor);
      width: 0;
      transition: width 0.3s ease;
    }

    &:hover::after {
      width: 100%;
    }

    &:hover {
      color: #273d24;
    }
  }

  &.open {
    display: block;
    .hamburger_auth {
      display: block; // Show only when the menu is open
      padding: 3px 26px;
      border-radius: 10px;
      border: 1px dotted var(--textColor);
      transition: background-color 0.3s;
    }
  }
}

.header-brand {
  h1 {
    color: #fafafa;
    font-size: 2.19vw;
    font-weight: 700;
  }
}

.auth-buttons {
  display: flex;
  align-items: center;
  gap: 30px;

  a {
    text-decoration: none;
    color: var(--textColor);
    font-size: 1.2vw;
    font-weight: 500;
  }

  .sign-up-button {
    background-color: var(--textColor);
    color: black;
    padding: 8px 28px;
    font-weight: 600;
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;

    &:hover {
      background-color: #f0f0f0;
    }
  }

  &.open {
    display: flex;
  }
}

.hamburger-menu {
  display: none;
  cursor: pointer;
  flex-direction: column;
  gap: 5px;
  margin-top: -18px;

  .line {
    width: 25px;
    height: 2px;
    background-color: var(--textColor);
    transition: all 0.3s ease;
  }

  .line.open:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }

  .line.open:nth-child(2) {
    opacity: 0;
  }

  .line.open:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
  }
}

@media (max-width: 970px) {
  .header-content {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    margin-top: -18px;
  }

  .header-links {
    display: none;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    gap: 25px;
    background-image: linear-gradient(#0faa2b, #057d1c);
    position: absolute;
    right: 0;
    top: 1px;
    width: 350px;
    height: 100vh;
    padding: 105px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 25px;
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform: translateX(100%);
    opacity: 0;
  }

  .header-links a {
    font-size: 18px;
  }

  .header-links.open {
    transform: translateX(0);
    opacity: 1;
    display: flex;
  }

  .auth-buttons {
    display: none;
    width: 100%;
    flex-direction: column;
    gap: 15px;
    padding-top: 10px;
  }

  .auth-buttons.open {
    display: flex;
  }

  .hamburger-menu {
    display: flex;
    justify-content: space-between;
    align-self: flex-end;
    margin-right: 10px;
    float: right;
    margin-top: -25px;
  }
}
@media (max-width: 830px) {
  .header-links a {
    font-size: 18px;
  }
  .header-links {
    width: 70vw;
    height: 100vh;
    gap: 40px;
  }
}
@media (max-width: 768px) {
  .header-brand h1 {
    font-size: 4.7vw !important;
    font-weight: 700;
    float: left;
    margin-left: -50px;
  }
  .hamburger-menu {
    margin-top: -40px;
  }
}

@media (max-width: 650px) {
  .hamburger-menu {
    margin-top: -30px;
  }
}

//smooth scrolling effect when navigating to a specific section
html {
  scroll-behavior: smooth;
}
