.HomeLandingPage_main {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  .HomeLandingPage_main_subContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    .socialMedia {
      position: absolute;
      width: 20px;
      display: flex;
      flex-direction: column;
      bottom: 180px;
      left: 70px;
      gap: 60px;
      img {
        cursor: pointer;
        height: 17px;
      }
    }

    .phone_container {
      position: absolute;
      gap: 60px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      align-self: center;
      bottom: -80px;

      .phoneImage {
        // height: 50%;
        width: 15.625vw;
      }
      .phoneImage1 {
        // height: 535px;
        width: 14.063vw;
      }
    }
  }

  .Header_Main_main {
    display: flex;
    justify-content: center;
  }

  .Header_Main {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 2;
  }

  .HomeLandingPage_background {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      object-fit: contain;
    }
  }

  .Header_Main_content {
    position: absolute;
    top: 16%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .Header_Main_content-title {
      font-size: 6.3vw;
      font-weight: 700;
      text-align: center;
      line-height: 1.2;
      color: var(--textColor);
    }

    .Header_Main_content-desc {
      font-size: 1.2vw;
      text-align: center;
      margin-top: 25px;
      max-width: 900px;
      color: var(--textColor);
    }
  }

  .storeContainer {
    height: 1000px;
  }
}

@media (max-width: 1250px) {
  .Header_Main_content-title {
    font-size: 4vw !important;
    margin-top: 10px;
  }
  .Header_Main_content-desc {
    // font-size: 1vw !important;
    max-width: 800px !important;
  }
}

@media (max-width: 1100px) {
  .phone_container {
    gap: 40px !important;
  }
  .phoneImage {
    width: 17vw !important;
  }
  .phoneImage1 {
    width: 15vw !important;
  }
  .Header_Main_content-desc {
    max-width: 600px !important;
  }
  .socialMedia {
    bottom: 70px !important;
    left: 70px;
    gap: 40px !important;
    img {
      cursor: pointer;
      height: 15px !important;
    }
  }
}

@media (max-width: 880px) {
  .Header_Main_content-title {
    font-size: 5vw !important;
  }

  .Header_Main_content-desc {
    font-size: 1.9vw !important;
    max-width: 500px !important;
  }
}
@media (max-width: 500px) {
  .Header_Main_content-title {
    padding: 0px;
  }
  .Header_Main_content-desc {
    // background-color: red;
    // font-size: 6.6vw !important;
    max-width: 300px !important;
    font-size: 2.6vw !important;
    margin-top: 0px;
  }
  .socialMedia {
    display: none !important;
  }
  .phone_container {
    gap: 20px !important;
    bottom: -70px !important;
  }
}

@media (max-width: 780px) {
  .socialMedia {
    display: none !important;
  }
  .HomeLandingPage_main {
    height: 100%;
    .HomeLandingPage_main_subContainer {
      .HomeLandingPage_background {
        width: 100%;
        height: 100%;
        // background-color: red;
        img {
          width: 100%;
          object-fit: contain;
        }
      }
    }
  }
}
